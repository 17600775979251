import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AppState } from '../core/reducers/index';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  auth$ = this.store.select('auth');
  token?: string | undefined;

  constructor(private store: Store<AppState>) {
    this.token = localStorage.getItem('JWT_TOKEN') || undefined;
    this.auth$.subscribe((res) => {
      this.token = res.token;
    });
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let modifiedReq = undefined;
    if (this.token) {
      modifiedReq = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${this.token}`),
      });
    }
    return next.handle(modifiedReq || request);
  }
}
